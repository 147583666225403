@import 'styles/sass/mixins/rem';
@import 'styles/sass/mixins/query';
.productItem {
  &__box {
    border-radius: rem(10);
    border: rem(1) solid var(--grayColor);
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
  }
}
