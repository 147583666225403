@import 'styles/sass/mixins/rem';

.productItemButton {
  width: 100%;
  margin-top: rem(10);
  &__link {
    display: block;
    text-align: center;
    > button {
      line-height: 1 !important;
      height: rem(38) !important;
      padding: rem(10) !important;
      border-radius: rem(6) !important;
      font-size: 1rem !important;
    }
  }
}
