@import 'styles/sass/mixins/rem';

.productListingBox {
  padding-bottom: rem(30);
  margin: 0 auto;

  &__seemoreBtn {
    > button {
      width: 100%;
      height: rem(50);
      line-height: 1;
      padding: 0;
    }
  }
}
