@import '@styles/sass/mixins/rem';
@import '@styles/sass/variables';

.zoom_in_effect span img {
  transform: scale(1);
  transition: 0.3s ease-in-out;
}

.zoom_in_effect span:hover img {
  transform: scale(1.025);
}

/* Set the image to fill its parent and make transparent */
.crop_center span img {
  margin: auto;
  display: block;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;
}

.image_border span {
  border-radius: rem(16);
  box-shadow: rem(0) rem(7) rem(15) rgba(42, 49, 203, 0.1);
}

.slideshow {
  &__remark {
    * {
      font-size: rem(14) !important;
      line-height: rem(20) !important;
    }
  }
}
