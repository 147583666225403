.singleLogo {
  img,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }

  img:hover,
  h1:hover,
  h2:hover,
  h3:hover,
  h4:hover,
  h5:hover,
  h6:hover,
  p:hover,
  span:hover {
    -webkit-transform: scale(1.025);
    transform: scale(1.025);
  }
}

.singleLogo__nolink {
  -webkit-transform: scale(1);
  transform: scale(1);
}
