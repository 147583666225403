@import 'styles/sass/variables';
@import 'styles/sass/mixins/rem';
@import 'styles/sass/mixins/query';

:export {
  textColor: $textColor;
}

.productItemTwoColumn {
  &__box {
    border-radius: rem(10);
    border: rem(1) solid $grayColor;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
  }

  &__image {
    display: block;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    img {
      max-height: rem(146);
    }
  }
  &__title {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    span {
      font-size: rem(16);
      line-height: rem(24);
      max-height: rem(50);
      font-weight: bold;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    > &__markdown {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      span,
      p {
        text-align: left !important;
        @include desktop {
          text-align: center !important;
        }
      }
    }
  }
  &__text {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: rem(15);
      font-weight: normal;
      line-height: rem(22);
    }

    p,
    span,
    li {
      font-size: rem(12);
      font-weight: normal;
      line-height: rem(22);
    }

    ul {
      margin: 0;
      padding: 0;
    }

    > &__markdown {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      span,
      p {
        text-align: left !important;
        @include desktop {
          text-align: center !important;
        }
      }

      li {
        @media screen and (max-width: '48em') {
          background-position: 2px 2px !important;
          padding-left: 20px !important;
          background-size: 12px;
        }
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      li,
      span {
        font-size: rem(14);
        line-height: rem(16);
        @include desktop {
          font-size: rem(14);
          line-height: rem(22);
        }
      }
    }
  }
}
