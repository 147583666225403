@import 'styles/sass/variables';
@import 'styles/sass/mixins/rem';
.productListingSeemoreButton {
  height: rem(50);

  > button {
    width: 100%;
    height: rem(50);
    line-height: 1;
    padding: 0;
    box-shadow: 0 rem(10) rem(10) rgba($primaryColor, 0.1);
    @media screen and (max-width: 768px) {
      width: 100% !important;
    }
  }
}
