@import '@styles/sass/mixins/rem';

.slide {
  &__title {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    span,
    p,
    li {
      @media only screen and (min-width: 768px) and (max-width: 959px) {
        font-size: rem(22);
        line-height: rem(26);
      }
      font-size: rem(26);
      font-weight: bold;
      line-height: rem(30);
    }
  }

  &__subtitle {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    span,
    li,
    p {
      font-size: rem(16);
      line-height: rem(22);
      font-weight: normal;
    }

    ul {
      margin: 0;
      padding: 0;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1440px) {
    &__desktopImage {
      img {
        object-fit: cover !important;
      }
    }
  }
}
