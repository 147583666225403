.crop_center img {
  margin: auto;
  display: block;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;
}

.desktopImage {
  display: block !important;
}

.mobileImage {
  display: none !important;
}

@media only screen and (max-width: 767px) {
  .desktopImage {
    display: none !important;
  }

  .mobileImage {
    display: block !important;
  }
}
