/* Using SCSS variables to store breakpoints */
$mobile-width: 22.5em;
$tablet-width: 48em;
$desktop-width: 60em;
$full-width: 75em;

@mixin mobile {
  @media screen and (min-width: #{$mobile-width}) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin desktop {
  @media screen and  (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin fullscreen {
  @media screen and  (min-width: #{$full-width}) {
    @content;
  }
}
